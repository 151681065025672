import * as React from "react"
import Layout from "../components/Layout";
import SEO from "../components/seo";

export default () => {
    return (<Layout>
        <div id="content" className="animate-fade-in">
            <h1>Datenschutzerklärung</h1>

            <h2>1. Datenschutz auf einen Blick</h2>

            <h3>Allgemeine Hinweise</h3>

            <p>Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
                passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
                persönlich
                identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer
                unter
                diesem Text aufgeführten Datenschutzerklärung.</p>

            <h3>Datenerfassung auf unserer Website</h3>

            <p><strong>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</strong></p>

            <p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können
                Sie
                dem Impressum dieser Website entnehmen.</p>

            <p><strong>Wie erfassen wir Ihre Daten?</strong></p>

            <p>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um
                Daten
                handeln, die Sie in ein Kontaktformular eingeben.</p>

            <p>Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
                allem
                technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung
                dieser
                Daten erfolgt automatisch, sobald Sie unsere Website betreten.</p>

            <p><strong>Wofür nutzen wir Ihre Daten?</strong></p>

            <p>Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere
                Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.</p>

            <p><strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong></p>

            <p>Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
                gespeicherten
                personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder
                Löschung
                dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich
                jederzeit
                unter der im Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht
                bei
                der zuständigen Aufsichtsbehörde zu.</p>

            <p>Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
                personenbezogenen Daten zu verlangen. Details hierzu entnehmen Sie der Datenschutzerklärung unter „Recht
                auf
                Einschränkung der Verarbeitung“.</p>

            <h3>Analyse-Tools und Tools von Drittanbietern</h3>

            <p>Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor
                allem
                mit Cookies und mit sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt in der
                Regel
                anonym; das Surf-Verhalten kann nicht zu Ihnen zurückverfolgt werden. Sie können dieser Analyse
                widersprechen oder sie durch die Nichtbenutzung bestimmter Tools verhindern. Detaillierte Informationen
                dazu
                finden Sie in der folgenden Datenschutzerklärung.</p>

            <p>Sie können dieser Analyse widersprechen. Über die Widerspruchsmöglichkeiten werden wir Sie in dieser
                Datenschutzerklärung informieren.</p>

            <h2>2. Allgemeine Hinweise und Pflichtinformationen</h2>

            <h3>Datenschutz</h3>

            <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
                personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie
                dieser
                Datenschutzerklärung.</p>

            <p>Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene
                Daten
                sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung
                erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem
                Zweck
                das geschieht.</p>

            <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
                Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
                nicht
                möglich.</p>

            <h3>Hinweis zur verantwortlichen Stelle</h3>

            <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>

            <p>Rechtsanwalt Thomas Reiser<br/>
                Hauptstraße 43<br/>
                89250 Senden</p>

            <p>Telefon: 07307 9517 05<br/>
                E-Mail: post@kanzlei-reiser.de</p>

            <p>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen
                über
                die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.)
                entscheidet.</p>

            <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>

            <p>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine
                bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an
                uns.
                Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>

            <h3>Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21
                DSGVO)</h3>

            <p><strong>Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, haben Sie
                jederzeit das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die
                Verarbeitung
                Ihrer personenbezogenen Daten Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen
                gestütztes Profiling. Die jeweilige Rechtsgrundlage, auf denen eine Verarbeitung beruht, entnehmen Sie
                dieser Datenschutzerklärung. Wenn Sie Widerspruch einlegen, werden wir Ihre betroffenen
                personenbezogenen
                Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die
                Verarbeitung
                nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen oder die Verarbeitung dient der
                Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen (Widerspruch nach Art. 21 Abs. 1
                DSGVO).</strong></p>

            <p><strong>Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, so haben Sie das
                Recht, jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke
                derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in
                Verbindung steht. Wenn Sie widersprechen, werden Ihre personenbezogenen Daten anschließend nicht mehr
                zum
                Zwecke der Direktwerbung verwendet (Widerspruch nach Art. 21 Abs. 2 DSGVO).</strong></p>

            <h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>

            <p>Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
                Aufsichtsbehörde,
                insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des
                mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher
                oder
                gerichtlicher Rechtsbehelfe.</p>

            <h3>Recht auf Datenübertragbarkeit</h3>

            <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
                automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
                aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
                verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>

            <h3>SSL- bzw. TLS-Verschlüsselung</h3>

            <p>Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum
                Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw.
                TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des
                Browsers
                von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</p>

            <p>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht
                von
                Dritten mitgelesen werden.</p>

            <h3>Auskunft, Sperrung, Löschung und Berichtigung</h3>

            <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
                Auskunft
                über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
                Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie
                zu
                weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum
                angegebenen Adresse an uns wenden.</p>

            <h3>Recht auf Einschränkung der Verarbeitung</h3>

            <p>Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                Hierzu
                können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Das Recht auf
                Einschränkung der Verarbeitung besteht in folgenden Fällen:</p>

            <ul>
                <li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen
                    wir
                    in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
                    Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                </li>
                <li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah / geschieht, können Sie
                    statt
                    der Löschung die Einschränkung der Datenverarbeitung verlangen.
                </li>
                <li>Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung,
                    Verteidigung
                    oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die
                    Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                </li>
                <li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen
                    Ihren
                    und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
                    überwiegen,
                    haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                </li>
            </ul>

            <p>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von
                ihrer
                Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
                von
                Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus
                Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats
                verarbeitet
                werden.</p>

            <h2>3. Datenerfassung auf unserer Website</h2>

            <h3>Cookies</h3>

            <p>Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen
                Schaden
                an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und
                sicherer
                zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser
                speichert.</p>

            <p>Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden nach Ende
                Ihres
                Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie diese
                löschen.
                Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.</p>

            <p>Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und
                Cookies
                nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie
                das
                automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von
                Cookies
                kann die Funktionalität dieser Website eingeschränkt sein.</p>

            <p>Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung
                bestimmter,
                von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind, werden auf Grundlage von
                Art. 6
                Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung
                von
                Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit andere Cookies
                (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser
                Datenschutzerklärung gesondert behandelt.</p>

            <h3>Server-Log-Dateien</h3>

            <p>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten
                Server-Log-Dateien,
                die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>

            <ul>
                <li>Browsertyp und Browserversion</li>
                <li>verwendetes Betriebssystem</li>
                <li>Referrer URL</li>
                <li>Hostname des zugreifenden Rechners</li>
                <li>Uhrzeit der Serveranfrage</li>
                <li>IP-Adresse</li>
            </ul>

            <p>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</p>

            <p>Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat
                ein
                berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website –
                hierzu
                müssen die Server-Log-Files erfasst werden.</p>

            <h3>Kontaktformular</h3>

            <p>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular
                inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall
                von
                Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p>

            <p>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt somit ausschließlich auf Grundlage
                Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen.
                Dazu
                reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
                Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.</p>

            <p>Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung
                auffordern,
                Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z.B. nach
                abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere
                Aufbewahrungsfristen – bleiben unberührt.</p>

            <h2>4. Analyse-Tools und Werbung</h2>

            <h3>Google Analytics</h3>

            <p>Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Inc.,
                1600
                Amphitheatre Parkway, Mountain View, CA 94043, USA.</p>

            <p>Google Analytics verwendet so genannte "Cookies". Das sind Textdateien, die auf Ihrem Computer
                gespeichert
                werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie
                erzeugten
                Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den
                USA
                übertragen und dort gespeichert.</p>

            <p>Die Speicherung von Google-Analytics-Cookies und die Nutzung dieses Analyse-Tools erfolgen auf Grundlage
                von
                Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der Analyse des
                Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu optimieren.</p>

            <p><strong>IP Anonymisierung</strong></p>

            <p>Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von
                Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des
                Abkommens
                über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen
                wird
                die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des
                Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website
                auszuwerten,
                um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der
                Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen
                von
                Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google
                zusammengeführt.</p>

            <p><strong>Browser Plugin</strong></p>

            <p>Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software
                verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
                Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung
                der
                durch den Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an
                Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden
                Link
                verfügbare Browser-Plugin herunterladen und installieren: <a
                    href="https://tools.google.com/dlpage/gaoptout?hl=de"
                    target="_blank">https://tools.google.com/dlpage/gaoptout?hl=de</a>.</p>

            <p><strong>Widerspruch gegen Datenerfassung</strong></p>

            <p>Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link
                klicken.
                Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser
                Website
                verhindert: <a href="javascript:gaOptout();">Google Analytics deaktivieren</a>.</p>

            <p>Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung
                von
                Google: <a href="https://support.google.com/analytics/answer/6004245?hl=de"
                           target="_blank">https://support.google.com/analytics/answer/6004245?hl=de</a>.</p>

            <p><strong>Auftragsverarbeitung</strong></p>

            <p>Wir haben mit Google einen Vertrag zur Auftragsverarbeitung abgeschlossen und setzen die strengen
                Vorgaben
                der deutschen Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um.</p>

            <p><strong>Demografische Merkmale bei Google Analytics</strong></p>

            <p>Diese Website nutzt die Funktion “demografische Merkmale” von Google Analytics. Dadurch können Berichte
                erstellt werden, die Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese
                Daten
                stammen aus interessenbezogener Werbung von Google sowie aus Besucherdaten von Drittanbietern. Diese
                Daten
                können keiner bestimmten Person zugeordnet werden. Sie können diese Funktion jederzeit über die
                Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch Google
                Analytics wie im Punkt “Widerspruch gegen Datenerfassung” dargestellt generell untersagen.</p>

            <h3>Matomo (ehemals Piwik)</h3>

            <p>Diese Website benutzt den Open Source Webanalysedienst Matomo. Matomo verwendet so genannte "Cookies".
                Das
                sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der
                Website
                durch Sie ermöglichen. Dazu werden die durch den Cookie erzeugten Informationen über die Benutzung
                dieser
                Website auf unserem Server gespeichert. Die IP-Adresse wird vor der Speicherung anonymisiert.</p>

            <p>Matomo-Cookies verbleiben auf Ihrem Endgerät, bis Sie sie löschen.</p>

            <p>Die Speicherung von Matomo-Cookies und die Nutzung dieses Analyse-Tools erfolgen auf Grundlage von Art. 6
                Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der anonymisierten Analyse
                des
                Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu optimieren.</p>

            <p>Die durch den Cookie erzeugten Informationen über die Benutzung dieser Website werden nicht an Dritte
                weitergegeben. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
                Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls
                nicht
                sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.</p>

            <p>Wenn Sie mit der Speicherung und Nutzung Ihrer Daten nicht einverstanden sind, können Sie die Speicherung
                und
                Nutzung hier deaktivieren. In diesem Fall wird in Ihrem Browser ein Opt-Out-Cookie hinterlegt, der
                verhindert, dass Matomo Nutzungsdaten speichert. Wenn Sie Ihre Cookies löschen, hat dies zur Folge, dass
                auch das Matomo Opt-Out-Cookie gelöscht wird. Das Opt-Out muss bei einem erneuten Besuch unserer Seite
                wieder aktiviert werden.</p>
            <h2>5. Plugins und Tools</h2>

            <h3>Google Web Fonts</h3>

            <p>Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google
                bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren
                Browsercache, um Texte und Schriftarten korrekt anzuzeigen.</p>

            <p>Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google aufnehmen.
                Hierdurch erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde.
                Die
                Nutzung von Google Web Fonts erfolgt im Interesse einer einheitlichen und ansprechenden Darstellung
                unserer
                Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.</p>

            <p>Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt.</p>

            <p>Weitere Informationen zu Google Web Fonts finden Sie unter <a
                href="https://developers.google.com/fonts/faq"
                target="_blank">https://developers.google.com/fonts/faq</a> und
                in der Datenschutzerklärung von Google: <a href="https://www.google.com/policies/privacy/"
                                                           target="_blank">https://www.google.com/policies/privacy/</a>.
            </p>

            <h3>Google Maps</h3>

            <p>Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist die Google Inc., 1600
                Amphitheatre
                Parkway, Mountain View, CA 94043, USA.</p>

            <p>Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP Adresse zu speichern. Diese
                Informationen werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.
                Der
                Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung.</p>

            <p>Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote
                und
                an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein
                berechtigtes
                Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.</p>

            <p>Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Google: <a
                href="https://www.google.de/intl/de/policies/privacy/"
                target="_blank">https://www.google.de/intl/de/policies/privacy/</a>.</p>

            <h3>Google reCAPTCHA</h3>

            <p>Wir nutzen “Google reCAPTCHA” (im Folgenden “reCAPTCHA”) auf unseren Websites. Anbieter ist die Google
                Inc.,
                1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (“Google”).</p>

            <p>Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf unseren Websites (z.B. in einem
                Kontaktformular)
                durch einen Menschen oder durch ein automatisiertes Programm erfolgt. Hierzu analysiert reCAPTCHA das
                Verhalten des Websitebesuchers anhand verschiedener Merkmale. Diese Analyse beginnt automatisch, sobald
                der
                Websitebesucher die Website betritt. Zur Analyse wertet reCAPTCHA verschiedene Informationen aus (z.B.
                IP-Adresse, Verweildauer des Websitebesuchers auf der Website oder vom Nutzer getätigte Mausbewegungen).
                Die
                bei der Analyse erfassten Daten werden an Google weitergeleitet.</p>

            <p>Die reCAPTCHA-Analysen laufen vollständig im Hintergrund. Websitebesucher werden nicht darauf
                hingewiesen,
                dass eine Analyse stattfindet.</p>

            <p>Die Datenverarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
                berechtigtes Interesse daran, seine Webangebote vor missbräuchlicher automatisierter Ausspähung und vor
                SPAM
                zu schützen.</p>

            <p>Weitere Informationen zu Google reCAPTCHA sowie die Datenschutzerklärung von Google entnehmen Sie
                folgenden
                Links: <a href="https://www.google.com/intl/de/policies/privacy/"
                          target="_blank">https://www.google.com/intl/de/policies/privacy/</a> und <a
                    href="https://www.google.com/recaptcha/intro/android.html"
                    target="_blank">https://www.google.com/recaptcha/intro/android.html</a>.
            </p>
        </div>
    </Layout>)
};

export const Head = () => {
    return (<SEO title={'Datenschutz'}></SEO>)
}

